import React, { useEffect, useState } from "react";
import Spin from "antd/es/spin";
import notification from "antd/es/notification";
import ConfigProvider from "antd/es/config-provider";
import Router from "./router";
import http from "../utils/http";

function Main() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setOpenBackdrop(true);
        return config;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "Terjadi kesalahan pada aplikasi, silahkan coba kembali lagi nanti."
              : "Terjadi kesalahan pada aplikasi, silahkan coba kembali lagi nanti.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        if (response?.config?.method !== "get") {
          if (response?.data?.message) {
            notification.success({
              message: response.data.message,
              placement: "bottomRight",
              duration: 5,
            });
          }
        }
        setOpenBackdrop(false);
        return response;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "Terjadi kesalahan pada aplikasi, silahkan coba kembali lagi nanti."
              : "Terjadi kesalahan pada aplikasi, silahkan coba kembali lagi nanti.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#11263C",
          colorLink: "#11263C",
          colorLinkHover: "#11263C",
        },
      }}
    >
      <Spin spinning={openBackdrop} tip="Mohon tunggu..." className="h-full">
        <Router />
      </Spin>
    </ConfigProvider>
  );
}

export default Main;
